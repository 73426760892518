import React, { useState, useEffect } from "react";
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'
import SearchResultPage from '../../../components/SearchResult/SearchResult'
import Results from "../../../templates/search-results-template";
import { UserObjectStoreProvider, useAuthState, getUserobjectData } from "@starberry/myaccount-website-utils";
import {
    parseSearchUrl,
    savedSearchParams,
    propertyH1
} from "../../../components/Property-search/utils";
const SearchResult = (props) => {
    // For myaccount
    useEffect(() => {
        if (isAuthenticated) {
          const getUserObjects = async () => {
              try {
                  const userObjects = await getUserobjectData()
                  setUserObjects(userObjects.data.data)
              } catch (e) {
                  console.log("e:", e)
              }
          }
          getUserObjects()
        }
    }, [])

    const [userObjects, setUserObjects] = useState({});
    const { state: authState, services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()

    const [showMenu, setShowMenu] = useState(false);
    const [render, setRender] = useState(false);

    const handlerClick = () => {
        setShowMenu(!showMenu)
        var root = document.getElementsByTagName('html')[0];
        if (showMenu == true) {
            document.body.className = '';
            root.className = '';
        } else {
            document.body.classList.add("overflow-hidden");
            root.classList.add("overflow-hidden");
        }
    }
    const [showSearch, setShowSearch] = useState(false);
    const handlerSearchClick = () => {
        setShowSearch(!showSearch)
        if (showSearch == true) {
            document.body.className = '';
        } else {
            document.body.classList.add("overflow-hidden");
        }
    }

    const fullpath = props.location.pathname
    const fullpathone = props.location
    const location = props.location
    const searchParams = parseSearchUrl("lettings", fullpath);
    useEffect(() => {
        setRender(true)
    })
    return (
        <>
            {render &&
                <div className={(showMenu || showSearch) ? "wrapper header-style open-search-block" : "wraapper header-style"}>
                    <Header showMenu={showMenu} handlerClick={handlerClick} handlerSearchClick={handlerSearchClick} showSearch={showSearch} fixed={false} />
                    <Results location={location} locationname="/property/to-rent/" pcategorytype="residential" fullpathone={fullpathone} fullpathname={fullpath} ptype="lettings" ptypetag="to-rent" userObjects={userObjects} /> 
                    <Footer searchtype='rentals' popularSearchType='propertyresults' searchBedroomfield={searchParams.bedrooms} searchPtype={searchParams.type} Searcharea={searchParams} SearchPrice={(searchParams.price.min !== "" || searchParams.price.max !== "") ? true : false} />
                </div>
            }
        </>
    )
}

const SearchResultComp = (props) => {
    return(
        <UserObjectStoreProvider>
            <SearchResult {...props} />
        </UserObjectStoreProvider>
    )
}


export default SearchResultComp;
